import type { FormValidationTranslationsObject } from "../../translation-keys/form-validation-keys.js";

const FormValidation: FormValidationTranslationsObject = {
  eclipse_FirstName_Error: "Please enter your First Name",
  eclipse_LastName_Error: "Please enter your Last Name",
  eclipse_Email_Error: "Please enter a valid business email",
  eclipse_Company_Error: "Please enter your Company",
  eclipse_Country_Error: "Please enter your Country",
  eclipse_Phone_Error: "Please enter a valid Phone Number",
  eclipse_Tier__c_Error: "Please enter your Company Size",
  eclipse_Industry_Error: "Please enter your Industry",
  eclipse_Department__c_Error: "Please enter your Department",
  eclipse_Email_Opt_In__c_Error: "",      //need this info
  eclipse_contactRequested_Error: "",     // Not currently Used
  eclipse_Webform_Comments_recent_value__c_Error: "",     //need this info
  eclipse_Latest_Solution_Interest__c_Error: "This field is required" ,      //need this info 
  eclipse_Entity_Error: "Please enter the Legal Entity Name",
  eclipse_NonBusinessEmail_Error: "Please enter a valid Email Address",
  eclipse_Partner_Opt_in_Processing__c_Error: "",
};

export default FormValidation;
